import React from 'react';
import {
	Grid,
	Typography,
	Card,
	CardContent,
	makeStyles,
	useMediaQuery,
} from '@material-ui/core';
import PortableText from 'react-portable-text';

const useStyles = makeStyles((theme) => ({
	root: {
		color: theme.workwaveBlue,
		padding: '0px',
		borderRadius: '20px',
		height: '150px',
		boxShadow: '0 10px 40px 0px rgba(0,0,0,0.15)',
		width: '400px',
		position: 'relative',
		// paddingTop: '2.75rem',
		// [theme.breakpoints.down('xs')]: {
		// 	maxWidth: '100% !important',
		// 	marginLeft: '0px',
		// 	marginRight: '0px',
		// 	width: '100%',
		// },
		// [theme.breakpoints.down('sm')]: {
		// 	maxWidth: '263px',
		// },
		[theme.breakpoints.down('md')]: {
			maxWidth: '500px',
		},
		// [theme.breakpoints.up('lg')]: {
		// 	maxWidth: '289px',
		// },
		transition: 'transform 0.25s',
		// boxShadow:
		// 	'0 13px 27px -5px rgba(50, 50, 93, 0), 0 8px 16px -8px rgba(0, 0, 0, 0)',
	},
	title: {
		fontSize: '1.25rem',
		fontWeight: 600,
		textAlign: 'left',
		color: '#183B56',
		marginTop: '.5rem',
		lineHeight: 1.3,
		// [theme.breakpoints.down('xs')]: {
		// 	marginLeft: '1rem',
		// },
	},
  address: {
    fontSize: '.8rem',
  },
	pos: {
		textAlign: 'left',
		lineHeight: '22px',
	},

}));

export const LocationCard = ({ location, index }) => {
	const classes = useStyles();
	const { companyLocationTitle, _rawCompanyLocationAddress } = location;
	const md = useMediaQuery('(max-width: 960px)');
	return (
		<Grid
			item
			xs={12}
			sm={6}
      lg={3}
			container
			direction='row'
			justifyContent={
				md ? 'center' : index % 2 === 0 ? 'flex-end' : 'flex-start'
			}
			alignItems='center'>
			<Card className={classes.root} elevation={0}>
				<CardContent
					style={{
						borderTop: `16px solid #2A7ABC`,
						minHeight: '150px',
						display: 'flex',
						flexDirection: 'column',
						padding: '16px',
						alignItems: 'space-evenly',
						justifyContent: 'center',
					}}>
					<Grid
						item
						container
						direction='row'
						justifyContent='flex-start'
						alignItems='center'>
						<Grid item xs={10}>
							<Typography variant='h6' className={classes.title}>
								{companyLocationTitle}
							</Typography>
						</Grid>
					</Grid>
					<Grid
						container
						item
						direction='row'
						justifyContent='flex-start'
						alignItems='flex-start'
            className={classes.address}>
						<PortableText content={_rawCompanyLocationAddress} />
					</Grid>
				</CardContent>
			</Card>
		</Grid>
	);
};
