import React from 'react';
import { BgImage } from 'gbimage-bridge';

import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography, Container, useTheme } from '@material-ui/core';

import { LocationCard } from './LocationCard';
import { ReverseWaveDownSVG } from '../../../WaveSVGs/ReverseWaveDownSVG';

const useStyles = makeStyles((theme) => ({
	background: {
		margin: '-8rem 0 -12rem 0',
	},
	images: {
		padding: '4rem 0 10rem 0',
		[theme.breakpoints.down('md')]: {
			padding: '3rem 0 6rem 0',
		},
	},
	header: {
		color: theme.white,
		marginTop: '-5rem',
    paddingBottom: '2rem',
		[theme.breakpoints.down('xs')]: {
			marginTop: '2rem',
      paddingBottom: 0,
		},
	},
}));

export const CompanyLocations = ({ companyLocation }) => {
	const theme = useTheme();
	const { companyLocationBg, companyLocationHeader, companyLocations } =
		companyLocation;
	const classes = useStyles();
	return (
		<BgImage
			image={companyLocationBg.asset?.gatsbyImageData}
			className={classes.background}>
			<ReverseWaveDownSVG fill='#002D5C' opacity='1' />
			<Container>
				<Grid
					container
					direction='column'
					justifyContent='center'
					alignItems='center'
					style={{ textAlign: 'center' }}>
					<Grid item>
						<Typography variant='h2' className={classes.header}>
							{companyLocationHeader}
						</Typography>
					</Grid>
				</Grid>
				<Grid
					container
					direction='row'
					justifyContent='center'
					spacing={2}
					className={classes.images}>
					{companyLocations.map((location, index) => (
						<LocationCard location={location} key={index} index={index} />
					))}
				</Grid>
			</Container>
		</BgImage>
	);
};
