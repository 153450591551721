import React, { useState } from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import Carousel from 'react-material-ui-carousel';

import { Grid, Typography, useMediaQuery, useTheme } from '@material-ui/core';

export const CompanyCoreValues = ({ companyCoreValues }) => {
	const { coreValuesHeader, coreValuesImages, coreValuesSubheader } =
		companyCoreValues;

	const [carouselOptions] = useState({
		autoPlay: true,
		animation: 'slide',
		indicators: false,
		timeout: 500,
		interval: 5000,
		navButtonsAlwaysVisible: true,
		// navButtonsAlwaysInvisible: true,
	});

	const md = useMediaQuery('(max-width: 960px)');
	const theme = useTheme();

	return (
		<div style={{ padding: '4rem 0' }}>
			<Grid
				container
				direction='column'
				justifyContent='center'
				alignItems='center'
				style={{ textAlign: 'center', paddingBottom: '4rem' }}>
				<Grid item xs={10}>
					<Typography variant='h2' color='primary'>
						{coreValuesHeader}
					</Typography>
				</Grid>
				<Grid item xs={12} sm={8} md={12} lg={10}>
					<Typography variant='body1'>{coreValuesSubheader}</Typography>
				</Grid>
			</Grid>
			{md ? (
				<Carousel
					style={{ width: '100%' }}
					autoPlay={carouselOptions.autoPlay}
					animation={carouselOptions.animation}
					indicators={carouselOptions.indicators}
					indicatorContainerProps={{
						style: {
							marginBottom: '16px',
						},
					}}
					interval={carouselOptions.interval}
					timeout={carouselOptions.timeout}
					navButtonsAlwaysVisible={carouselOptions.navButtonsAlwaysVisible}
					navButtonsAlwaysInvisible={carouselOptions.navButtonsAlwaysInvisible}
					navButtonsProps={{
						style: {
							backgroundColor: theme.workwaveBlue,
							marginBottom: '5rem',
							padding: '5px',
						},
					}}>
					{coreValuesImages.map((image, index) => (
						<div
							style={{
								display: 'flex',
								justifyContent: 'center',
								flexDirection: 'column',
								height: '380px',
							}}>
							<GatsbyImage
								image={image?.coreValueImage.asset?.gatsbyImageData}
								key={index}
								style={{ margin: '0 auto' }}
								imgStyle={{ objectFit: 'contain' }}
							/>
							<Typography
								variant='body1'
								style={{
									margin: '0 auto',
									marginTop: '1rem',
									textAlign: 'center',
									maxWidth: '80%',
								}}>
								{image.coreValueDescription}
							</Typography>
						</div>
					))}
				</Carousel>
			) : (
				<Grid container direction='row' justifyContent='center' spacing={4}>
					{coreValuesImages.map((image, index) => (
						<Grid item sm={6} md={4} key={index}>
							<Grid container justifyContent='center'>
								<GatsbyImage
									image={image?.coreValueImage.asset?.gatsbyImageData}
								/>
							</Grid>
							<Grid container justifyContent='center'>
								<Typography
									variant='body1'
									style={{ margin: '1.5rem 0', textAlign: 'center' }}>
									{image.coreValueDescription}
								</Typography>
							</Grid>
						</Grid>
					))}
				</Grid>
			)}
		</div>
	);
};
