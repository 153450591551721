import React from 'react';
import PortableText from 'react-portable-text';

import { Grid, Typography, makeStyles, useMediaQuery } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
	upper: {
		padding: '0 0 4rem 0',
		[theme.breakpoints.down('md')]: {
			paddingTop: '4rem',
			paddingBottom: '1rem',
		},
	},
	lower: {
		padding: '4rem 0 0 0',
	},
}));

export const CompanyCenterText = ({ content, lower }) => {
	const classes = useStyles();
	const md = useMediaQuery('(max-width: 960px)');
	return (
		<Grid
			container
			justifyContent='center'
			alignItems='center'
			className={lower ? classes.lower : classes.upper}>
			<Grid item md={8} style={{ textAlign: md ? 'left' : 'center' }}>
				<PortableText
					content={content}
					serializers={{
						h2: ({ children }) => (
							<Typography
								variant='h2'
								style={{ color: '#19305A', fontWeight: 700 }}>
								{children}
							</Typography>
						),
						normal: ({ children }) => (
							<Typography variant='body1' style={{ lineHeight: '32px' }}>
								{children}
							</Typography>
						),
						strong: ({ children }) => (
							<span style={{ fontWeight: 500, color: lower && '#19305A' }}>
								{children}
							</span>
						),
					}}
				/>
			</Grid>
		</Grid>
	);
};
