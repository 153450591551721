import React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';

import {
	Grid,
	Typography,
	Card,
	CardContent,
	makeStyles,
	useMediaQuery,
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
	root: {
		borderRadius: '20px',
		margin: '0 1rem',
		zIndex: 1,
		cursor: 'pointer',
    position: 'relative',
	},
	image: {
		transition: 'all .5s',
		'&:hover': {
			scale: 1.02,
		},
	},
	date: {
		// borderRadius: '13px',
		// background: theme.white,
		color: theme.workwaveBlue,
    fontSize: '.875rem',
		// position: 'relative',
		// top: '-250px',
		// left: '15px',
		padding: '10px 0',
		// maxWidth: '90px',
		// opacity: 0.8,
		fontWeight: 600,
		// [theme.breakpoints.down('lg')]: {
		// 	top: '-195px',
		// },
		// [theme.breakpoints.down('md')]: {
		// 	top: '-240px',
		// 	left: '10px',
		// },
		// [theme.breakpoints.down('sm')]: {
		// 	top: '-280px',
		// },
	},
  ctaContainer: {
    position: 'absolute',
    bottom: '10px',
  },
	arrow: {
		marginLeft: '.25rem',
		transition: 'transform .25s ease-in-out',
		display: 'inline-block',
	},
	ctaLink: {
		marginBottom: '.5rem',
		'&:hover': {
			'& $arrow': { transform: 'translateX(10px)' },
		},
	},
}));

export const NewsCard = ({ card, index }) => {
	const { link, image, synopsis, date, ctaText } = card;
	const lg = useMediaQuery('(max-width: 1024px)');
	const md = useMediaQuery('(max-width: 820px)');
	const sm = useMediaQuery('(max-width: 768px)');
	const xs = useMediaQuery('(max-width: 481px)');
	const classes = useStyles();
	return (
		<Card
			elevation={2}
			className={classes.root}
			onClick={() => window.open(link, '_blank')}
			style={{ height: xs ? '526px' : sm ? '550px' : md ? '500px' : lg ? '508px' : '530px' }}>
			<GatsbyImage
				image={image?.asset.gatsbyImageData}
				// className={classes.image}
			/>
			
			<CardContent
				style={{
					height: md ? '11rem' : lg ? '12rem' : '11rem',
					paddingTop: '0px',
					display: 'flex',
					flexDirection: 'column',
					justifyContent: 'space-between',
				}}>
        <div className={classes.date}>{date}</div>
				<Grid
					style={{ height: sm ? null : '100%', margin: '0' }}
					container
					direction='column'
					justifyContent='flex-start'
					alignItems='flex-start'>
					<Typography variant='body1'>{synopsis}</Typography>
				</Grid>
				<Grid container className={classes.ctaContainer}>
					<Typography
						variant='body2'
						className={classes.ctaLink}
						style={{
							color: '#002D5C',
							fontWeight: 600,
							fontSize: xs ? '1.16rem' : '1.25rem',
							marginTop: '1rem',
						}}>
						{ctaText}
						<span className={classes.arrow}>→</span>
					</Typography>
				</Grid>
			</CardContent>
		</Card>
	);
};
