import React from 'react';
import PortableText from 'react-portable-text';
import { BgImage } from 'gbimage-bridge';
import { GatsbyImage } from 'gatsby-plugin-image';

import { makeStyles } from '@material-ui/core/styles';
import {
	Grid,
	Typography,
	Button,
	useMediaQuery,
	Paper,
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
	center: {
		padding: '2rem 4rem',
		borderRadius: '20px',
		marginTop: '155px',
		// boxShadow: 'none',
		[theme.breakpoints.down('sm')]: {
			padding: '2rem .5rem',
		},
		[theme.breakpoints.down('xs')]: {
			padding: '2rem .25rem',
		},
		'& div': {
			[theme.breakpoints.down('xs')]: {
				maxWidth: '300px',
			},
		},
	},
}));

export const CompanyCareersMosaic = ({ companyMosaic }) => {
	const classes = useStyles();

	const {
		mosaicCtaLink,
		mosaicCtaText,
		mosaicDesktop,
		mosaicLogo,
		mosaicMobile,
		mosaicTablet,
		_rawMosaicCalloutText,
	} = companyMosaic;
	const md = useMediaQuery('(max-width: 960px)');
	const sm = useMediaQuery('(max-width: 599px)');

	return (
		<div style={{ padding: '4rem 0' }}>
			<BgImage
				style={{
					height: '765px',
					borderRadius: '20px',
					overflow: 'hidden',
					// backgroundSize: 'cover',
				}}
				image={
					sm
						? mosaicMobile.asset?.gatsbyImageData
						: md
						? mosaicTablet.asset?.gatsbyImageData
						: mosaicDesktop.asset?.gatsbyImageData
				}>
				<GatsbyImage
					image={mosaicLogo.asset?.gatsbyImageData}
					style={{
						position: 'absolute',
						top: 0,
						left: 0,
					}}
				/>
				<Grid
					container
					justifyContent='center'
					alignItems='center'
					style={{ height: '100%' }}>
					<Paper className={classes.center}>
						<PortableText
							content={_rawMosaicCalloutText}
							serializers={{
								h2: ({ children }) => (
									<Typography
										variant='h3'
										color='primary'
										style={{
											lineHeight: 0.9,
											fontWeight: 600,
											textAlign: 'center',
											paddingBottom: '.5rem',
										}}>
										{children}
									</Typography>
								),
								normal: ({ children }) => (
									<Typography
										variant='body1'
										style={{
											textAlign: 'center',
											paddingBottom: '2rem',
										}}>
										{children}
									</Typography>
								),
							}}
						/>
						<Grid container justifyContent='center'>
							<Button
								component='a'
								href={mosaicCtaLink}
								target='_blank'
								variant='contained'
								color='secondary'>
								{mosaicCtaText}
							</Button>
						</Grid>
					</Paper>
				</Grid>
			</BgImage>
		</div>
	);
};
