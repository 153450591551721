import React from 'react';

import { Container, makeStyles, Grid } from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';
// import { CompanyCard } from './UpperSectionComponents/CompanyCard';
// import { CompanyCallout } from './UpperSectionComponents/CompanyCallout';
import { CompanyCenterText } from './UpperSectionComponents/CompanyCenterText';
import { CompanyCenterWave } from './UpperSectionComponents/CompanyCenterWave';
import { CompanyFeature } from './UpperSectionComponents/CompanyFeature';
import { AwardsCards } from './UpperSectionComponents/AwardsCard';

const useStyles = makeStyles((theme) => ({
  awardsSection: {
    position: 'relative',
    marginTop: '32px',
    paddingBottom: '0px',
    display: 'flex',
    zIndex: 2,
    [theme.breakpoints.down('sm')]: {
      flexWrap: 'wrap',
      justifyContent: 'center',
    },
  },
  whiteBox: {
    backgroundColor: '#ffffff',
    borderRadius: '20px',
    border: '1px solid #E5EAF4',
    boxShadow: '0px 10px 40px 0px rgba(0, 0, 0, 0.1)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flex: '1 1 calc(100% / 6 - 20px)',
    margin: '0 16px',
    overflow: 'hidden',
    '&:first-of-type': {
      marginLeft: 0,
    },
    '&:last-of-type': {
      marginRight: 0,
    },
    [theme.breakpoints.down('sm')]: {
      flex: '1 1 calc(100% / 3 - 40px)',
      marginBottom: '32px',
      '&:nth-of-type(1), &:nth-of-type(4)': {
        marginLeft: 0,
      },
      '&:nth-of-type(3), &:nth-of-type(6)': {
        marginRight: 0,
      },
    },
  },
}));

export const CompanyUpperSection = ({ companyUpperSection }) => {
	const classes = useStyles();
  const {
    _rawCenterTextTop,
    companyFeatures,
    _rawCenterTextBottom,
    centerWaveBackground,
    centerWaveMobile,
    centerWaveDesktop,
    cardHeaderOverlay,
    cardHeaderOverlayMobile,
    awards = [],  // Correctly defaults to an empty array if undefined
    awardsHeader,
  } = companyUpperSection;

  const md = useMediaQuery('(max-width: 960px)');
  return (
    <>
      {/* Awards Section */}
			
      <Container>
        <CompanyCenterText content={_rawCenterTextTop} />
        {/* <CompanyCard
					background={
						md
							? cardHeaderMobile?.asset.gatsbyImageData.images.fallback.src
							: cardHeader?.asset.gatsbyImageData.images.fallback.src
					}
					desktop={cardHeaderOverlay?.asset.gatsbyImageData}
					mobile={cardHeaderOverlayMobile?.asset.gatsbyImageData}
					content={_rawCardContent}
				/>
				<CompanyCallout
					content={_rawCalloutText}
					image={calloutImage.asset.gatsbyImageData}
				/> */}
        {companyFeatures.map((feature, index) => (
          <CompanyFeature key={index} feature={feature} index={index} />
        ))}
        <CompanyCenterText content={_rawCenterTextBottom} lower />
      </Container>
			<Container className={classes.awardsSection}>
      <AwardsCards awardsArray={awards} awardsHeader={awardsHeader} />
    </Container>
      <CompanyCenterWave
        background={centerWaveBackground}
        stats={md ? centerWaveMobile : centerWaveDesktop}
      />
    </>
  );
};
