import React from 'react';
import { BgImage } from 'gbimage-bridge';
import { GatsbyImage } from 'gatsby-plugin-image';

import { Container, Grid, makeStyles } from '@material-ui/core';

import { WaveUpSVG } from '../../../WaveSVGs/WaveUpSVG';
import { ReverseWaveDownSVG } from '../../../WaveSVGs/ReverseWaveDownSVG';

const useStyles = makeStyles((theme) => ({
	imgCont: {
		marginTop: '4rem 0',
		// paddingTop: '6rem',
		zIndex: -10000,
	},
	images: {
		marginTop: '-6rem',
		padding: '12rem 0 15rem 0',
		[theme.breakpoints.down('xl')]: {
			padding: '10rem 0 12rem 0',
		},
		// [theme.breakpoints.down('lg')]: {
		// 	padding: '22rem 0 12rem 0',
		// },
		[theme.breakpoints.down('md')]: {
			padding: '13rem 0 12rem 0',
		},
		[theme.breakpoints.down('sm')]: {
			padding: '8rem 0 6rem 0',
		},
		[theme.breakpoints.down('sm')]: {
			padding: '8rem 0 6rem 0',
		},
		image: {
			[theme.breakpoints.down('xs')]: {
				marginBottom: '1rem',
			},
		},
	},
}));

export const CompanyCenterWave = ({ background, stats }) => {
	const classes = useStyles();

	return (
		<>
			<BgImage
				className={classes.imgCont}
				image={background.asset?.gatsbyImageData}>
				<ReverseWaveDownSVG fill='#FFF' />
				<Container>
					<Grid
						className={classes.images}
						container
						spacing={3}
						direction='row'
						justifyContent='space-evenly'
						alignItems='center'>
						{stats.map((stat, index) => (
							<Grid
								item
								sm={6}
								md={3}
								key={index}
								container
								justifyContent='center'
								className={classes.image}>
								<GatsbyImage image={stat?.asset?.gatsbyImageData} />
							</Grid>
						))}
					</Grid>
				</Container>
			</BgImage>
			<WaveUpSVG fill='#FFF' />
		</>
	);
};
