import React, { useRef } from 'react';
import Slider from 'react-slick';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { makeStyles } from '@material-ui/core/styles';
import {
	Grid,
	Typography,
	Container,
	Button,
	useMediaQuery,
} from '@material-ui/core';

import { NewsCard } from './NewsCard';
import { WaveDownSVG } from '../../../WaveSVGs/WaveDownSVG';

const useStyles = makeStyles((theme) => ({
	topWave: {
		background: theme.workwaveBlue,
		padding: '2rem 0 5rem 0',
		zIndex: -1,
		[theme.breakpoints.down('md')]: {
			padding: '0 0 5rem 0',
		},
	},
	slider: {
		zIndex: 1,
		marginBottom: '2rem',
		[theme.breakpoints.down('sm')]: {
			marginBottom: '5rem',
		},
		[theme.breakpoints.down('xs')]: {
			marginBottom: '7rem',
		},
	},
	dots: {
		bottom: -35,
		marginTop: '1rem',
		'& li.slick-active button::before': {
			color: theme.white,
		},
		'& li': {
			'& button::before': {
				fontSize: theme.typography.pxToRem(14),
				color: theme.white,
				opacity: 0.5,
			},
		},
	},
	nextArrow: {
		position: 'absolute',
		right: '-35px',
		color: 'white',
		bottom: '43%',
		opacity: 0.6,
		cursor: 'pointer',
		transition: 'all .5s',
		'&:hover': {
			opacity: 1,
		},
		[theme.breakpoints.down('lg')]: { right: '-15px' },
	},
	prevArrow: {
		position: 'absolute',
		left: '-35px',
		color: 'white',
		top: '50%',
		opacity: 0.6,
		cursor: 'pointer',
		transition: 'all .5s',
		'&:hover': {
			opacity: 1,
		},
		[theme.breakpoints.down('lg')]: { left: '-15px' },
	},
}));

function NextArrow({ onClick }) {
	const classes = useStyles();
	return (
		<FontAwesomeIcon
			size='2x'
			icon={['fas', 'arrow-right']}
			onClick={onClick}
			className={classes.nextArrow}
		/>
	);
}

function PrevArrow({ onClick }) {
	const classes = useStyles();
	return (
		<FontAwesomeIcon
			size='2x'
			icon={['fas', 'arrow-left']}
			onClick={onClick}
			className={classes.prevArrow}
		/>
	);
}

export const CompanyNews = ({ companyNews }) => {
	const sliderRef = useRef(null);
	const { newsHeader, newsSubeader, newsCtaText, newsLink, companyNewsCards } =
		companyNews;
	// const theme = useTheme();
	const classes = useStyles();
	const md = useMediaQuery('(max-width: 960px)');

	let settings = {
		className: 'center',
		// centerMode: true,
		arrows: !md,
		dots: md,
		infinite: true,
		speed: 300,
		slidesToShow: 3,
		slidesToScroll: 1,
		initialSlide: 0,
		nextArrow: <NextArrow />,
		prevArrow: <PrevArrow />,
		responsive: [
			{
				breakpoint: 960,
				settings: {
					slidesToShow: 2,
					slidesToScroll: 1,
					dotsClass: `slick-dots ${classes.dots}`,
				},
			},
			{
				breakpoint: 600,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
					dotsClass: `slick-dots ${classes.dots}`,
				},
			},
		],
	};

	return (
		<div style={{ paddingTop: '4rem' }}>
			<Container style={{ paddingBottom: md ? '3rem' : 0 }}>
				<Grid
					container
					direction='column'
					justifyContent='center'
					alignItems='center'
					style={{ textAlign: 'center', paddingBottom: '4rem' }}>
					<Grid item>
						<Typography variant='h2' color='primary'>
							{newsHeader}
						</Typography>
					</Grid>
					<Grid item>
						<Typography variant='body1'>{newsSubeader}</Typography>
					</Grid>
				</Grid>
				<Grid
					container
					direction='row'
					justifyContent='center'
					alignItems='center'>
					<a
						href={newsLink.current}
						target='_blank'
						rel='noopener'
						aria-label="Opens in a new tab"
						style={{ textDecoration: 'none' }}>
						<Button
							color='secondary'
							size='large'
							variant='contained'
							// onClick={(e) => navigate(newsLink.current)}
						>
							{newsCtaText}
						</Button>
					</a>
				</Grid>
			</Container>
			<WaveDownSVG fill='#FFFFFF' />
			<div className={classes.topWave}>
				<Container>
					<Slider {...settings} ref={sliderRef} className={classes.slider}>
						{companyNewsCards.map((card, index) => (
							<NewsCard card={card} key={index} index={index} />
						))}
					</Slider>
				</Container>
			</div>
		</div>
	);
};
