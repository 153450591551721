import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { navigate } from 'gatsby';
import { Grid, Typography, Button } from '@material-ui/core';
import { CheckmarkCards } from '../../CheckmarkCards';

const useStyles = makeStyles((theme) => ({
	perk: {
		background: theme.white,
		border: '1px solid #E5EAF4',
		boxShadow: '0px 10px 40px rgba(0, 0, 0, 0.15)',
		borderRadius: '20px',
		height: '95px',
		marginTop: '1.5rem',
		paddingRight: '46px',
		// [theme.breakpoints.down('md')]: {
		//  	width: '100%',
		//  },
		// maxWidth: '90%',
		// marginBottom: '2rem',
	},
	icon: {
		margin: '0 .5rem 24px 15px',
		[theme.breakpoints.down('md')]: {
			margin: '0 0 0 15px',
		},
	},
	perkHeader: {
		padding: '2rem 0',
	},
	perkText: {
		fontWeight: 700,
		lineHeight: 1.3,
		marginLeft: '20px',
		fontSize: '1.3rem',
		[theme.breakpoints.down('md')]: {
			margin: '0 0 0 20px',
			fontSize: '1.1rem',
		},
		[theme.breakpoints.down('sm')]: {
			margin: '0 0 0 12px',
			fontSize: '1.2rem',
		},
	},
	arrow: {
		marginLeft: '.25rem',
		transition: 'transform .25s ease-in-out',
		display: 'inline-block',
	},
	link: {
		fontWeight: 600,
		textTransform: 'capitalize',
		letterSpacing: '.2px',
		padding: '2rem 0',
		'&:hover': {
			cursor: 'pointer',
			opacity: 0.8,
			'& $arrow': { transform: 'translateX(10px)' },
		},
	},
}));

export const CompanyIndustryCards = ({
	cardsHeader,
	blurbs,
	// marketplaceLink,
	// marketplaceText,
}) => {
	const classes = useStyles();

	return (
		<div style={{ paddingBottom: '4rem' }}>
			<Grid
				container
				direction='column'
				justifyContent='center'
				alignItems='center'>
				<Typography variant='h2' color='primary' className={classes.perkHeader}>
					{cardsHeader}
				</Typography>
			</Grid>
			<CheckmarkCards cards={blurbs} />
			<Grid
				container
				direction='row'
				justifyContent='center'
				alignItems='center'>
				<Button
					variant='contained'
					color='secondary'
					style={{ marginTop: '2rem' }}
					onClick={(e) => {
						e.preventDefault();
						navigate('/industries');
					}}>
					VIEW ALL INDUSTRIES
				</Button>
			</Grid>
		</div>
	);
};
