import React from 'react';

import { Container } from '@material-ui/core';

import { CompanyIndustryCards } from './LowerSectionComponents/CompanyIndustryCards';
import { CompanyCoreValues } from './LowerSectionComponents/CompanyCoreValues';
import { CompanyCareersMosaic } from './LowerSectionComponents/CompanyCareersMosaic';
import { CompanyEquityPartners } from './LowerSectionComponents/CompanyEquityPartners';
import { CompanyFeature } from './UpperSectionComponents/CompanyFeature';
import { CompanyNews } from './LowerSectionComponents/CompanyNews';
import { CompanyLocations } from './LowerSectionComponents/CompanyLocations';

export const CompanyLowerSection = ({ companyLowerSectionCombined }) => {
	const {
		blurbs,
		blurbsHeader,
		companyLowerSection,
		companyLocationBg,
		companyLocationHeader,
		companyLocations,
	} = companyLowerSectionCombined;

	const companyMosaic = companyLowerSection.companyMosaic;

	const companyLocation = {
		companyLocationBg,
		companyLocationHeader,
		companyLocations,
	};

  const companyLowerFeatures = companyLowerSection.companyLowerFeatures;
	return (
		<>
			<Container>
				<CompanyIndustryCards blurbs={blurbs} cardsHeader={blurbsHeader} />
				<CompanyCoreValues companyCoreValues={companyLowerSection} />
				<CompanyCareersMosaic companyMosaic={companyMosaic} />
				<CompanyEquityPartners companyEquity={companyLowerSection} />
				{companyLowerFeatures.map((feature, index) => (
					<CompanyFeature key={index} feature={feature} index={index} />
				))}
			</Container>
			<CompanyNews companyNews={companyLowerSection} />
			<CompanyLocations companyLocation={companyLocation} />
		</>
	);
};
