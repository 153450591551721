import React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import PortableText from 'react-portable-text';

import { Grid, Typography, useMediaQuery } from '@material-ui/core';

export const CompanyFeature = ({ feature, index }) => {
	const lg = useMediaQuery('(max-width: 1280px)');

	return (
		<Grid
			container
			style={{ padding: lg ? '2rem 0 ' : '4rem 0' }}
			spacing={4}
			justifyContent={lg ? 'center' : 'space-between'}
			alignItems='center'
			direction={index === 0 ? 'row-reverse' : 'row'}>
			<Grid item lg={index === 0 ? 6 : 7}>
				<GatsbyImage
					image={feature.companyFeatureImage?.asset.gatsbyImageData}
					imgStyle={{ objectFit: 'contain' }}
				/>
			</Grid>
			<Grid item lg={index === 0 ? 6 : 5}>
				<PortableText
					content={feature._rawCompanyFeatureContent}
					serializers={{
						h2: ({ children }) => (
							<Typography
								variant='h2'
								style={{ color: '#19305A', fontWeight: 700, paddingBottom: '1rem' }}>
								{children}
							</Typography>
						),
						normal: ({ children }) => (
							<Typography variant='body1' style={{ lineHeight: '32px' }}>
								{children}
							</Typography>
						),
						strong: ({ children }) => (
							<span style={{ fontWeight: 500, color: '#19305A' }}>
								{children}
							</span>
						),
					}}
				/>
			</Grid>
		</Grid>
	);
};
