import React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import PortableText from 'react-portable-text';
// import ReactPlayer from 'react-player/lazy';
import loadable from '@loadable/component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography, Container, useMediaQuery } from '@material-ui/core';

import { WaveUpHero } from '../../../WaveSVGs/WaveUpHero';

const Video = loadable(() => import('../../../Home/Hero/Video'));

const useStyles = makeStyles((theme) => ({
	'@keyframes pulseBorder': {
		'0%': {
			transform: 'translateX(-50%) translateY(-50%) translateZ(0) scale(1)',
			opacity: '1',
		},
		'100%': {
			transform: 'translateX(-50%) translateY(-50%) translateZ(0) scale(1.5)',
			opacity: '0',
		},
	},

	root: {
		position: 'relative',
		width: '100%',
		padding: '6rem 0',
		maxWidth: '100vw',
		overflow: 'hidden',

		[theme.breakpoints.down('xs')]: {
			paddingTop: '2rem',
			paddingBottom: '2rem',
		},
	},
	// bgWhiteLight: {
	// 	width: '100%',
	// 	height: '200%',
	// 	background: '#FFFFFF',
	// 	position: 'absolute',
	// 	top: '-50%',
	// 	left: '-50%',
	// 	// marginLeft: '25%',
	// 	boxShadow: '4px 4px 6px rgb(50 50 93 / 8%), 0 1px 3px rgb(0 0 0 / 6%)',
	// 	[theme.breakpoints.down('sm')]: {
	// 		top: '0',
	// 		left: '0',
	// 		marginLeft: '0',
	// 	},
	// },
	// playButton: {
	// 	height: '100px',
	// 	width: '100px',
	// 	background: theme.workwaveBlue,
	// 	color: theme.white,
	// 	fontSize: '1.5rem',
	// 	borderRadius: '50%',
	// 	position: 'absolute',
	// 	left: '84%',
	// 	marginTop: '-20px',
	// 	marginLeft: '-50px',
	// 	top: '37%',
	// 	opacity: 1,
	// 	display: 'flex',
	// 	alignItems: 'center',
	// 	justifyContent: 'center',
	// 	boxShadow: '0 4px 6px rgb(50 50 93 / 11%), 0 1px 3px rgb(0 0 0 / 8%)',
	// 	cursor: 'pointer',
	// 	zIndex: 5,

	// 	'&::before': {
	// 		content: `''`,
	// 		position: 'absolute',
	// 		zIndex: '-1',
	// 		left: '50%',
	// 		top: '50%',
	// 		transform: 'translateX(-50%) translateY(-50%)',
	// 		display: 'block',
	// 		width: '100px',
	// 		height: '100px',
	// 		background: '#002D5C',
	// 		borderRadius: '50%',
	// 	},

	// 	'&:hover': {
	// 		'& $playButtonEffect': { transform: 'scale(1.1)' },
	// 		'&::before': {
	// 			animation: '$pulseBorder 1500ms ease-out infinite',
	// 		},
	// 	},
	// 	[theme.breakpoints.down('sm')]: {
	// 		display: 'none',
	// 	},
	// },
	// playButtonEffect: {
	// 	padding: '130px',
	// 	position: 'absolute',
	// 	left: '84%',
	// 	marginTop: '-100px',
	// 	marginLeft: '-128px',
	// 	top: '36%',
	// 	borderRadius: '10px',
	// 	zIndex: 5,
	// 	transition: 'all 1s ease-in-out',
	// 	height: '520px',
	// 	width: '350px',

	// 	[theme.breakpoints.down('sm')]: {
	// 		display: 'none',
	// 	},
	// },
	// same styles as home
	playButton: {
		height: '100px',
		width: '100px',
		background: '#fff',
		color: '#002d5c',
		fontSize: '1.5rem',
		borderRadius: '50%',
		position: 'absolute',
		left: '84%',
		marginTop: '-20px',
		marginLeft: '-50px',
		top: '37%',
		opacity: 1,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		boxShadow: '0 4px 6px rgb(50 50 93 / 11%), 0 1px 3px rgb(0 0 0 / 8%)',
		cursor: 'pointer',
		zIndex: 5,

		'&::before': {
			content: `''`,
			position: 'absolute',
			zIndex: '-1',
			left: '50%',
			top: '50%',
			transform: 'translateX(-50%) translateY(-50%)',
			display: 'block',
			width: '100px',
			height: '100px',
			background: '#fff',
			borderRadius: '50%',
		},

		'&:hover': {
			'& $playButtonEffect': { transform: 'scale(1.1)' },
			'&::before': {
				animation: '$pulseBorder 1500ms ease-out infinite',
			},
		},
		[theme.breakpoints.down('sm')]: {
			display: 'none',
		},
	},
	playButtonEffect: {
		background: 'rgba(0, 45, 92,0.95)',
		padding: '130px',
		position: 'absolute',
		left: '84%',
		marginTop: '-100px',
		marginLeft: '-128px',
		top: '36%',
		borderRadius: '50%',
		mixBlendMode: 'multiply',
		boxShadow: '0 4px 6px rgb(50 50 93 / 11%), 0 1px 3px rgb(0 0 0 / 8%)',
		zIndex: 5,
		transition: 'all 1s ease-in-out',

		[theme.breakpoints.down('sm')]: {
			display: 'none',
		},
	},
	heroVideoBg: {
		position: 'absolute',
		top: '-30px',
		left: '65%',
		width: '35%',
		height: '100%',
		objectFit: 'cover',
		zIndex: 0,
		boxShadow: '0 0 0 60px #002d5c, 0 0 0 120px #0f95a4',
		borderRadius: '50% 0 0 50%',
		[theme.breakpoints.down('sm')]: {
			display: 'none',
		},
	},

	heroImgBg: {
		display: 'inline-block',
		position: 'absolute',
		top: '-30px',
		left: '65%',
		width: '35%',
		height: '100%',
		objectFit: 'cover',
		zIndex: -1,
		boxShadow: '0 0 0 60px #002d5c, 0 0 0 120px #0f95a4',
		borderRadius: '55% 0 0 55%',
		[theme.breakpoints.down('sm')]: {
			left: 0,
			top: 0,
			width: '100%',
			height: '100%',
			boxShadow: 'none',
			borderRadius: '0px',
		},
	},

	text: {
		color: theme.workwaveBlue,
		textAlign: 'left',
		//lineHeight: '.7',
	},
	header: {
		lineHeight: '1.1',
		color: theme.workwaveBlue,
		paddingBottom: '2rem',
		letterSpacing: '.2px',
		[theme.breakpoints.down('lg')]: {
			fontSize: '3rem',
		},
		[theme.breakpoints.down('xs')]: {
			fontSize: '2.2rem',
		},
	},
	heroBody: {
		color: theme.workwaveBlue,
		paddingTop: '1.25rem',
		paddingBottom: '1.5rem',
		fontFamily: 'Roboto',
		fontWeight: 500,
		lineHeight: '30px',
	},
	heroContent: {
		position: 'relative',
		lineHeight: '28px',
		fontSize: '32px',
		color: '#F8FBFF',
		marginTop: '1rem',
		fontWeight: 600,
		textAlign: 'left',
		[theme.breakpoints.down('md')]: {
			fontSize: '30px',
		},
		[theme.breakpoints.down('sm')]: {
			textAlign: 'center',
			fontSize: '22px',
			fontWeight: 600,
			left: 0,
		},
	},
	playerWrapper: {
		position: 'relative',
		paddingTop: '56.25%',
		minHeight: '100%',
		minWidth: '100%',
		border: '1px solid #d8dee0',
		marginTop: '.5rem',
	},
	reactPlayer: {
		position: 'absolute',
		top: 0,
		left: 0,
	},
	waveEdge: {
		position: ' absolute',
		left: 0,
		bottom: 0,

		//ignore the css below. It's just to create a css triangle but we need an SVG for this shape because of the "curve" this triangle has in the mockup.
		width: 0,
		height: 0,
		borderStyle: 'solid',
		borderWidth: '300px 0 0 400px',
		borderColor: 'transparent transparent transparent #2F7FC1',
		[theme.breakpoints.down('lg')]: {
			borderWidth: '240px 0 0 400px',
		},
		[theme.breakpoints.down('sm')]: {
			display: 'none',
		},
	},
}));

export const CompanyHero = ({ hero, setHeroModalOpen, setFormModalOpen }) => {
	const classes = useStyles();

	const md = useMediaQuery('(max-width: 960px)');
	// const sm = useMediaQuery('(max-width: 600px)');
	return (
		<>
			<Grid
				container
				direction='row'
				alignItems='center'
				className={classes.root}>
				<div className={classes.bgWhiteLight}>
					{/* <div
						className={classes.playButtonEffect}
						style={{
							background: `url(${hero.wistiaImage?.asset?.gatsbyImageData.images.fallback.src})`,
						}}
					/> */}
					{/* <div
						className={classes.playButton}
						onClick={(e) => setHeroModalOpen(true)}>
						<FontAwesomeIcon icon={['fas', 'play']} size='lg' />
					</div> */}
				</div>
				{/* <video className={classes.heroVideoBg} autoPlay loop muted>
					<source
						type='video/mp4'
						src='https://media.istockphoto.com/videos/sunset-over-ocean-video-id1360657069'
					/>
					<source
						type='video/webm'
						src='https://media.istockphoto.com/videos/sunset-over-ocean-video-id1360657069'></source>
				</video> */}
				<GatsbyImage
					image={
						md
							? hero.mobileBackground?.asset?.gatsbyImageData
							: hero.backgroundImage?.asset?.gatsbyImageData
					}
					alt='company-hero'
					className={classes.heroImgBg}
				/>
				<Container style={{ position: 'inherit' }}>
					<Grid
						container
						item
						direction='column'
						justify='center'
						alignItems='flex-start'
						className={classes.heroContent}
						xs={12}
						md={8}>
						{hero._rawContent.map((content, index) => (
							<PortableText
								key={index}
								content={content}
								className={classes.text}
								serializers={{
									h1: ({ children }) => (
										<Grid item>
											<Typography
												variant='h1'
												className={classes.header}
												style={{ fontWeight: 400 }}>
												{children}
											</Typography>
										</Grid>
									),
									bold: ({ children }) => (
										<Grid item>
											<Typography variant='h1' className={classes.header}>
												{children}
											</Typography>
										</Grid>
									),
									normal: ({ children }) => {
										return (
											<Grid item xs={12} md={10}>
												<Typography
													variant='body1'
													style={{
														lineHeight: '28px',
														fontFamily: 'Roboto',
														fontSize: '1.375rem',
													}}>
													{children}
												</Typography>
											</Grid>
										);
									},
								}}
							/>
						))}
					</Grid>
					{/* {md && (
						<Grid
							container
							direction='row'
							justifyContent='center'
							alignItems='center'
							style={{ marginTop: '2rem' }}
							item
							xs>
							<div className={classes.playerWrapper}>
								<Video
									url={hero.heroVideo}
									className={classes.reactPlayer}
									height='100%'
									width='100%'
									controls={true}
								/>
							</div>
						</Grid>
					)} */}
				</Container>
				<div className={classes.waveEdge} />
				<WaveUpHero height='213' width='100%' fill='white' company />
			</Grid>
		</>
	);
};
