import React, { useState, useContext } from 'react';
import { graphql } from 'gatsby';
import loadable from '@loadable/component';

import { Container } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { CompanyHero } from '../components/General/Company/Hero/CompanyHero';
import { HeroModal } from '../components/General/Company/Hero/HeroModal';
import { WaveUpSVG } from '../components/WaveSVGs/WaveUpSVG';
import { SEO } from '../components/SEO';
import { CompanyUpperSection } from '../components/General/Company/CompanyUpperSection';
import { CompanyLowerSection } from '../components/General/Company/CompanyLowerSection';
// import { Form } from '../components/ContactForm/Form';
import FormModalContext from '../context/FormModalContext';

const Form = loadable(() => import('../components/ContactForm/Form'));

const useStyles = makeStyles((theme) => ({
  text: {
    color: '#5A7184',
    '& a': {
      color: theme.workwaveBlue,
    },
  },
  logoSection: {
    marginTop: '-1rem',
    background: 'linear-gradient(180deg, #055291 45.8%, #002D5C 100%)',
    padding: '0 0 11rem 0',
    [theme.breakpoints.down('lg')]: {
      padding: '4rem 0 10rem 0',
      marginTop: '-2rem',
    },
    [theme.breakpoints.down('md')]: {
      padding: '5rem 0 9rem 0',
    },
    [theme.breakpoints.down('sm')]: {
      padding: '8rem 0 7rem 0',
    },
    [theme.breakpoints.down('xs')]: {
      padding: '8rem 0 4rem 0',
      // marginTop: '-6rem',
    },
  },
  logos: {
    marginTop: '-7rem',
    // paddingBottom: '4rem',
  },
  wave: {
    marginTop: '16rem',
    [theme.breakpoints.down('lg')]: {
      marginTop: '12rem',
    },
    [theme.breakpoints.down('md')]: {
      marginTop: '14rem',
    },
    [theme.breakpoints.down('sm')]: {
      marginTop: '12rem',
    },
  },
}));

const CompanyPage = ({ data, location }) => {
  const company = data.company.edges[0].node;
  const classes = useStyles();

  const {
    metaTitle,
    metaDescription,
    blurbs,
    blurbsHeader,
    companyLowerSection,
    companyLocationBg,
    companyLocationHeader,
    companyLocations,
    companyUpperSection,
    awardsHeader,
    awards,
    hero,
  } = company;

  const companyLowerSectionCombined = {
    blurbs,
    blurbsHeader,
    companyLowerSection,
    companyLocationBg,
    companyLocationHeader,
    companyLocations,
  };

  const [heroModalOpen, setHeroModalOpen] = useState(false);

  const { formModalOpen } = useContext(FormModalContext);

  return (
    <>
      <SEO title={metaTitle} description={metaDescription} />
      <CompanyHero hero={hero} setHeroModalOpen={setHeroModalOpen} />
			<CompanyUpperSection companyUpperSection={companyUpperSection} />
      <CompanyLowerSection
        companyLowerSectionCombined={companyLowerSectionCombined}
      />
      <Container>
        {formModalOpen ? (
          <Form
            privacy
            noForm
            location={location}
            pardotUrl="https://go.workwave.com/l/1042281/2023-11-16/65swnn"
          />
        ) : null}
      </Container>
      <HeroModal
        open={heroModalOpen}
        setHeroModalOpen={setHeroModalOpen}
        wistiaLink={hero.heroVideo}
        playing
      />
      <div className={classes.wave}>
        <WaveUpSVG height="213" width="100%" fill="#f4f8ff" />
      </div>
    </>
  );
};

export const query = graphql`
  query CompanyPageQuery {
    company: allSanityCompany {
      edges {
        node {
          metaTitle
          metaDescription
          hero {
            videoBackground
            heroVideo
            mobileBackground {
              asset {
                gatsbyImageData(placeholder: BLURRED)
              }
            }
            backgroundImage {
              asset {
                gatsbyImageData(placeholder: BLURRED)
              }
            }
            _rawContent
          }
          companyUpperSection {
            awardsHeader
            awards {
              title
              image {
                asset {
                  gatsbyImageData(placeholder: BLURRED)
                }
              }
            }
            _rawCenterTextTop
            companyFeatures {
              companyFeatureImage {
                asset {
                  gatsbyImageData(placeholder: BLURRED, width: 700, height: 500)
                }
              }
              _rawCompanyFeatureContent
            }
            _rawCenterTextBottom
            centerWaveBackground {
              asset {
                gatsbyImageData(placeholder: BLURRED)
              }
            }
            centerWaveDesktop: centerWaveImagesDesktop {
              asset {
                gatsbyImageData(placeholder: BLURRED)
              }
            }
            centerWaveMobile: centerWaveImagesMobile {
              asset {
                gatsbyImageData(placeholder: BLURRED)
              }
            }
          }
          blurbsHeader
          blurbs {
            title
            icon
            accentColor {
              hexValue
            }
            link
          }
          companyLowerSection {
            coreValuesHeader
            coreValuesSubheader
            coreValuesImages {
              coreValueTitle
              coreValueImage {
                asset {
                  gatsbyImageData(
                    placeholder: BLURRED
                    height: 300
                    width: 295
                    layout: CONSTRAINED
                  )
                }
              }
              coreValueDescription
            }
            companyMosaic {
              mosaicLogo {
                asset {
                  gatsbyImageData(placeholder: BLURRED)
                }
              }
              mosaicDesktop {
                asset {
                  gatsbyImageData(placeholder: BLURRED)
                }
              }
              mosaicTablet {
                asset {
                  gatsbyImageData(placeholder: BLURRED)
                }
              }
              _key
              mosaicMobile {
                asset {
                  gatsbyImageData(placeholder: BLURRED)
                }
              }
              _rawMosaicCalloutText
              mosaicCtaText
              mosaicCtaLink
            }
            _rawEquityHeader
            equityLogos {
              asset {
                gatsbyImageData(placeholder: BLURRED, width: 160)
              }
            }
            companyLowerFeatures {
              companyFeatureImage {
                asset {
                  gatsbyImageData(placeholder: BLURRED, width: 700, height: 500)
                }
              }
              _rawCompanyFeatureContent
            }
            _rawLeadershipLink
            newsHeader
            newsSubeader
            newsCtaText
            newsLink {
              current
            }
            companyNewsCards {
              title
              image {
                asset {
                  gatsbyImageData(placeholder: BLURRED, height: 347)
                }
              }
              date
              synopsis
              link
              ctaText
            }
          }
          companyLocationHeader
          companyLocationBg {
            asset {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
          companyLocations {
            companyLocationTitle
            _rawCompanyLocationAddress
          }
        }
      }
    }
  }
`;

export default CompanyPage;
